import React from "react"
import { prussianBlue } from "../styles/colors";
import "../styles/bootstrap-italia.min.css"

const SmallButton = ({ icon, label, onClick, disabled }) => {
  const buttonStyle = {
    backgroundColor: prussianBlue,
    borderRadius: "25px",
    justifyContent: "center",
  };
  return (
    <button disabled={disabled} style={buttonStyle} className="btn btn-primary btn-xs btn-icon" onClick={onClick}>
      {icon && (
        <svg className="icon icon-white">
          <use href={`../static/bootstrap-italia/svg/sprite.svg#${icon}`}></use>
        </svg>
      )}
      <span>{label}</span>
    </button>
  );
};

const MediumButton = ({ icon, label, onClick, disabled }) => {
  const buttonStyle = {
    margin:10,
    backgroundColor: prussianBlue,
    borderRadius: "25px",
    justifyContent: "center",
  };
  return (
    <button disabled={disabled} style={buttonStyle} className="btn btn-primary btn-sm btn-icon" onClick={onClick}>
      {icon && (
        <svg className="icon icon-white">
          <use href={`../static/bootstrap-italia/svg/sprite.svg#${icon}`}></use>
        </svg>
      )}
      <span>{label}</span>
    </button>
  );
};

export { SmallButton, MediumButton };
