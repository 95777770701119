import "./styles/styles.css";
import "typeface-titillium-web";

import React, { useEffect, useState } from "react";
import { UserProvider } from "./context/UserContext";
import { I18nProvider } from "./context/I18nContext";
import { PCRCProvider } from "./context/PCRCContext";
import { HelperProvider } from "./context/HelperContext";
import { DocumentProvider } from "./context/DocumentContext";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Main from "./components/Main";
import Loading from "./components/Loading";
import PDFViewer from "./components/PDFViewer";


const App = () => {
  const [connected, setConnected] = useState(false);

  const connectedHandler = (event) => {
    console.log(event);
    setConnected(true);
  };

  useEffect(() => {
    document.addEventListener("connected", connectedHandler);
  }, []);

  return (
    <PCRCProvider>
      <I18nProvider>
        <HelperProvider>
          <UserProvider>
            <DocumentProvider>
              <Router>
                <Switch>
                  <Route path="/pdf">
                    <PDFViewer></PDFViewer>
                  </Route>
                  <Route path="/">{connected ? <Main></Main> : <Loading></Loading>}</Route>
                </Switch>
              </Router>
            </DocumentProvider>
          </UserProvider>
        </HelperProvider>
      </I18nProvider>
    </PCRCProvider>
  );
};

export default App;
