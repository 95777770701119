import React, { createContext, useReducer, useContext, useEffect } from "react";

const HelperStateContext = createContext();
const HelperDispatchContext = createContext();

function HelperReducer(state, action) {
  switch (action.type) {
    case "SET_HELPER_LEVEL": {
      return { ...state, helperLevel: action.value };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function HelperProvider({ children }) {
  const [state, dispatch] = useReducer(HelperReducer, {
    helperLevel: 3,
  });

  const helperHandler = (event) => {
    const { detail } = event;
    const { helperLevel } = detail;
    console.log({ helperLevel });
    dispatch({ type: "SET_HELPER_LEVEL", payload: helperLevel });
  };

  useEffect(() => {
    document.addEventListener("helper-change", helperHandler);
  }, []);

  return (
    <HelperStateContext.Provider value={state}>
      <HelperDispatchContext.Provider value={dispatch}>{children}</HelperDispatchContext.Provider>
    </HelperStateContext.Provider>
  );
}

function useHelperState() {
  const context = useContext(HelperStateContext);
  if (context === undefined) {
    throw new Error("useHelperState must be used within a HelperProvider");
  }
  return context;
}

function useHelperDispatch() {
  const context = useContext(HelperDispatchContext);
  if (context === undefined) {
    throw new Error("useHelperState must be used within a HelperProvider");
  }
  return context;
}

function useHelper() {
  return [useHelperState(), useHelperDispatch()];
}

export { HelperProvider, useHelper };
