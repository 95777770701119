import React, { useState, useEffect } from "react";
import { fetchDocPDF } from "../context/DocumentContext";
import PDFViewer from "pdf-viewer-reactjs";
import { useI18n } from "../context/I18nContext";
import styled from "styled-components";
import { MediumButton } from "./Buttons";

const EmptyComponent = () => <div></div>;

const PDFModal = () => {
  const [{ translate }] = useI18n();

  const [base64, setBase64] = useState(null);
  const [error, setError] = useState(false);

  let search = window.location.search;
  let params = new URLSearchParams(search);
  const docId = params.get("docId");
  const token = params.get("token");
  const repoId = params.get("repoId");

  const getPDF = async () => {
    setError(false);
    try {
      const response = await fetchDocPDF(token, docId, repoId);
      if (response.status === "Success") {
        setBase64(response.document);
      } else {
        setError(true);
      }
    } catch (err) {
      setError(true);
    }
  };

  useEffect(() => {
    getPDF();
  }, []);

  return !error ? (
    base64 && (
      <>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
          <a href={`data:application/pdf;base64,${base64}`} target="_blank" download="documento.pdf">
            <MediumButton
              label={"Scarica il documento"}
            ></MediumButton>
          </a>
        </div>
        <PDFViewer
          document={{
            base64,
          }}
          navigation={EmptyComponent}
          canvasCss="pdf-canvas"
          css="pdf-container"
        />
      </>
    )
  ) : (
      <div>{translate("error_failure")}</div>
    );
};

export default PDFModal;
