import React, { useEffect } from "react";
import { usePCRC } from "../context/PCRCContext";
import { useUser } from "../context/UserContext";
import { useI18n } from "../context/I18nContext";
import Breadcrumb from "./Breadcrumb";
import DocumentsTable from "./DocumentsTable";
import { PageTitle, PageSubTitle } from "./PageElements";
import HelpTooltip from "./HelpTooltip";

const Main = () => {
  const { isLogged } = useUser();
  const [{ translate }] = useI18n();

  const { setHeaderImages } = usePCRC();

  useEffect(() => {
    setHeaderImages({
      sx: `${window.location.href}${process.env.PUBLIC_URL}/header/sx.png`,
      center: false,
    });
  }, []);

  return (
    isLogged && (
      <div>
        <Breadcrumb></Breadcrumb>
        <PageTitle as="div">
          {translate("ccd")}
        </PageTitle>
        <PageSubTitle>{translate("ccd_subtitle")}</PageSubTitle>
        <DocumentsTable></DocumentsTable>
      </div>
    )
  );
};

export default Main;
