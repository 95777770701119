import styled from "styled-components";
import { ceruleanBlue } from "../styles/colors";
import Tooltip from "rc-tooltip";
import { useHelper } from "../context/HelperContext";
import React, { useEffect, useState } from "react";

const TooltipText = styled.span`
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
  max-width: 300px;
  color: ${ceruleanBlue};
`;

const HelpTooltip = ({ placement = "right", text = "", showIcon = true, modal = false }) => {
  const Icon = () => (
    <svg className="icon icon-sm">
      <use href={`${process.env.PUBLIC_URL}bootstrap-italia/svg/sprite.svg#it-help-circle`}></use>
    </svg>
  );

  const AlwaysOnTooltip = (
    <Tooltip
      arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
      placement={placement}
      trigger={["hover"]}
      overlay={showIcon && <TooltipText>{text}</TooltipText>}
      visible={true}
    >
      <svg className="icon icon-sm">
        <use href={`${process.env.PUBLIC_URL}bootstrap-italia/svg/sprite.svg#it-help-circle`}></use>
      </svg>
    </Tooltip>
  );

  const OnHoverTooltip = (
    <Tooltip
      arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
      placement={placement}
      trigger={["hover"]}
      overlay={showIcon && <TooltipText>{text}</TooltipText>}
    >
      <svg className="icon icon-sm">
        <use href={`${process.env.PUBLIC_URL}bootstrap-italia/svg/sprite.svg#it-help-circle`}></use>
      </svg>
    </Tooltip>
  );

  const [helperState] = useHelper();
  const { helperLevel } = helperState;
  const [component, setComponent] = useState(OnHoverTooltip);

  useEffect(() => {
    if (helperLevel === 1) {
      setComponent(AlwaysOnTooltip);
    } else {
      setComponent(Icon);
      setTimeout(() => {
        setComponent(OnHoverTooltip);
      }, 10);
    }
  }, [helperLevel]);

  return <div style={{ display: "inline-block", margin: "0 10px" }}>{component}</div>;
};

export default HelpTooltip;
