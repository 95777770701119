import React, { Fragment } from "react";
import { useUser } from "../context/UserContext";
import { useI18n } from "../context/I18nContext";
import { usePCRC } from "../context/PCRCContext";
import { useDocument } from "../context/DocumentContext";
import styled from "styled-components";
import { Header2 } from "../styles/text-styles";
import { prussianBlue, lightGray, blueGray } from "../styles/colors";

const DocumentsTable = () => {
  const { token } = useUser();

  const state = useDocument();
  const { documents } = state;
  const [{ translate, getFormattedDate }] = useI18n();

  const { showModal } = usePCRC();

  const Table = styled.table`
    ${Header2}
    font-size: 15px;
    margin-top: 3rem;
    border-collapse: collapse;
    color: ${prussianBlue};
  `;

  const TableHeader = styled.th`
    font-weight: 700;
    font-style: normal;
    border-right: 2px solid ${lightGray};
    min-width: 10rem;
    padding: 5px 0;

    padding-left: 20px;
    text-align: justify;
  `;

  const TableHeaderRow = styled.tr`
    border-top: 2px solid ${blueGray};
  `;

  const Td = styled.td`
    font-weight: normal;
    padding: 5px 0;
    padding-left: 20px;
  `;

  const FakeTd = styled.td`
    height: 25px;
  `;

  const Tr = styled.tr`
    background: ${(props) => (props.even ? "transparent" : blueGray)};
    &.real {
      cursor: pointer;
    }
  `;

  const FirstTd = styled(Td)`
    display: flex;
    justify-content: flex-start;
  `;

  const DocIcon = styled.img`
    width: 18px;
    margin-right: 5px;
    :hover {
      cursor: pointer;
    }
  `;

  const openDoc = async (docId, repoId) => {
    showModal(`/pdf?docId=${docId}&repoId=${repoId}&token=${token}`);
  };

  return (
    <Fragment>
      <Table>
        <thead>
          <TableHeaderRow>
            <TableHeader>{translate("doc_table_docname")}</TableHeader>
            <TableHeader>{translate("doc_table_facility_name")}</TableHeader>
            <TableHeader>{translate("doc_table_issue_date")}</TableHeader>
            <TableHeader>{translate("doc_table_reception_date")}</TableHeader>
            <TableHeader className="border-none">{translate("doc_table_digitalization_date")}</TableHeader>
          </TableHeaderRow>
        </thead>
        <tbody>
          {!!documents.length
            ? documents.map(
                (
                  {
                    id,
                    repoId,
                    nomeDocumento,
                    strutturaErogante,
                    dataAccettazione,
                    dataDimissione,
                    dataDigitalizzazione,
                  },
                  index
                ) => {
                  return (
                    <Tr className="real" key={index} even={(index + 1) % 2 === 0} onClick={() => openDoc(id, repoId)}>
                      <FirstTd>
                        <DocIcon alt="doc" src={`${process.env.PUBLIC_URL}/icona_doc.png`}></DocIcon> {nomeDocumento}
                      </FirstTd>
                      <Td>{strutturaErogante}</Td>
                      <Td>{getFormattedDate(dataDimissione)}</Td>
                      <Td>{getFormattedDate(dataAccettazione)}</Td>
                      <Td>{getFormattedDate(dataDigitalizzazione)}</Td>
                    </Tr>
                  );
                }
              )
            : [0, 1, 2, 3, 4, 5].map((el) => (
                <Tr key={el} even={(el + 1) % 2 === 0}>
                  {[0, 1, 2, 3, 4, 5].map((el) => (
                    <FakeTd key={el}></FakeTd>
                  ))}
                </Tr>
              ))}
        </tbody>
      </Table>
    </Fragment>
  );
};

export default DocumentsTable;
