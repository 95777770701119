const prussianBlue = "#00446d";
const niceBlue = "rgba(21, 105, 155, 1)";
const lightBlue = "rgba(217, 241, 255, 0.49)";
const turquoise = "#00abac";
const intenseBlue = "#0066cc";
const lightGray = "#eeeeee";
const darkBlue = "#455b71";
const azure = "#0caeaf";
const ceruleanBlue = "#0073e6";
const breadcrumbBlue = "#5C6F82";
const azureBlue = "#E6E9F2";
const blueGray = "#eaf0fb";

export {
  azure,
  breadcrumbBlue,
  prussianBlue,
  niceBlue,
  lightBlue,
  turquoise,
  intenseBlue,
  lightGray,
  darkBlue,
  ceruleanBlue,
  azureBlue,
  blueGray,
};
