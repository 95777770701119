import React from "react";
import styled from "styled-components";

import { breadcrumbBlue } from "../styles/colors";
import { TextStyle2 } from "../styles/text-styles";
import { useI18n } from "../context/I18nContext";
import { usePCRC } from "../context/PCRCContext";

const StyledFragment = styled.div`
  
  ${TextStyle2}
  color: ${breadcrumbBlue};
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
  &.active {
    font-weight: bold;
    &:hover {
      cursor: default;
      text-decoration: none;
    }
  }
`;

const StyledSeparator = styled.div`
  ${TextStyle2};
  margin: 0 5px;
`;

const Breadcrumb = () => {
  const [i18nState] = useI18n();
  const { translate } = i18nState;

  const { navigateTo } = usePCRC();

  return (
    <div className="flex" style={{ marginTop: "10px" }}>
      <StyledFragment onClick={() => navigateTo("/")}>{translate("breadcrumb_home")}</StyledFragment>
      <StyledSeparator>{translate("breadcrumb_separator")}</StyledSeparator>
      <StyledFragment className="active">{translate("ccd")}</StyledFragment>
    </div>
  );
};

export default Breadcrumb;
