import React, { createContext, useContext, useEffect, useState } from "react";
import { API_BASE_URL } from "../constants/constants";
import axios from "axios";

import { useUser } from "./UserContext";
import { usePCRC } from "./PCRCContext";
import { useI18n } from "./I18nContext";

const fetchDocPDF = async (token, idDoc, repoId) => {
  try {
    const { data } = await axios.get(`${API_BASE_URL}/cce/getDocument?documentId=${idDoc}&repositoryId=${repoId}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return null;
  }
};

const DocumentStateContext = createContext();

function DocumentProvider({ children }) {
  const initialState = {
    documents: [],
  };

  const [state, setState] = useState(initialState);

  const { token, user } = useUser();
  const [{ translate }] = useI18n();
  const { showMessage } = usePCRC();

  useEffect(() => {
    if (token) {
      fetchDocuments(token, user);
    }
  }, [token]);

  async function fetchDocuments(token, user) {
    try {
      const { fiscalCode } = user;
      const { data } = await axios.post(
        `${API_BASE_URL}/cce/findDocuments`,
        { patientId: fiscalCode, statuses: ["Approved"] },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (data.status === "Success") {
        const documents = data.DocumentDTO;
        if (documents.length) {
          setState({ ...state, documents });
        } else {
          showMessage({
            status: "error",
            title: translate("error_title"),
            description: translate("error_no_documents"),
          });
        }
      } else {
        showMessage({
          status: "error",
          title: translate("error_title"),
          description: translate("error_failure"),
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  return <DocumentStateContext.Provider value={state}>{children}</DocumentStateContext.Provider>;
}

function useDocument() {
  const context = useContext(DocumentStateContext);
  if (context === undefined) {
    throw new Error("useDocumentState must be used within a DocumentProvider");
  }
  return context;
}

export { DocumentProvider, useDocument, fetchDocPDF };
