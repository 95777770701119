import React, { createContext, useContext } from "react";

function comunicateToParent({ type, payload }) {
  try {
    window.parentIFrame.sendMessage({ type, payload });
  } catch (err) {
    console.log(err);
  }
}

const navigateTo = (page) => comunicateToParent({ type: "NAVIGATE_TO", payload: page });
const setHeaderImages = (payload) => comunicateToParent({ type: "SET_HEADER_IMAGES", payload });
const showModal = (html) => comunicateToParent({ type: "SHOW_MODAL", payload: html });
const showMessage = (payload) => comunicateToParent({ type: "SHOW_MESSAGE", payload });

const PCRCStateContext = createContext();

function PCRCProvider({ children }) {
  return (
    <PCRCStateContext.Provider value={{ navigateTo, setHeaderImages, showModal, showMessage }}>
      {children}
    </PCRCStateContext.Provider>
  );
}

function usePCRC() {
  const context = useContext(PCRCStateContext);
  if (context === undefined) {
    throw new Error("usePCRCState must be used within a PCRCProvider");
  }
  return context;
}

export { PCRCProvider, usePCRC };
