import React from "react";
import { useI18n } from "../context/I18nContext";

const Loading = () => {
  const [i18nState] = useI18n();
  const { translate } = i18nState;

  return <div style={{ marginTop: "2rem" }}>{translate("loading")}</div>;
};

export default Loading;
