import React, { createContext, useContext, useEffect, useState } from "react";
import { AUTHENTICATION_STRING, TOKEN_URL } from "../constants/constants";
import axios from "axios";

const UserStateContext = createContext();

async function fetchToken() {
  try {
    const { data } = await axios.post(
      `${TOKEN_URL}?grant_type=client_credentials`,
      {},
      {
        headers: {
          Authorization: `Basic ${AUTHENTICATION_STRING}`,
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );
    const token = data.access_token;
    return token;
  } catch (error) {
    return "";
  }
}

function UserProvider({ children }) {
  const initialState = {
    session: null,
    user: null,
    isLogged: false,
    token: "",
  };

  const [state, setState] = useState(initialState);

  const handleSessionChange = async (event) => {
    try {
      const { detail } = event;
      const { user, session, isLogged } = detail;

      let token = null;

      if (isLogged) {
        token = await fetchToken();
      }
      setState({ session, user, isLogged: !!session, token });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    document.addEventListener("session-change", handleSessionChange);
  }, []);

  return <UserStateContext.Provider value={state}>{children}</UserStateContext.Provider>;
}

function useUser() {
  const context = useContext(UserStateContext);
  if (context === undefined) {
    throw new Error("useUserState must be used within a UserProvider");
  }
  return context;
}

export { UserProvider, useUser };
